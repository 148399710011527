import discord from './icons/discord.svg';
import github from './icons/github.svg';
import medium from './icons/medium.svg';
import telegram from './icons/telegram.svg';
import twitter from './icons/twitter.svg';
import facebook from './icons/facebook.svg';
import reddit from './icons/reddit.svg';
import instagram from './icons/instagram.svg';
import youtube from './icons/youtube.svg';

import './footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-container">
                    <a href="https://t.me/GRECASPlatform" target='_blank' rel="noreferrer"><img src={telegram} alt="Telegram"></img></a>
                    <a href="https://twitter.com/GrecasToken" target='_blank' rel="noreferrer"><img src={twitter} alt="Twitter"></img></a>
                    <a href="https://medium.com/@grecas" target='_blank' rel="noreferrer"><img src={medium} alt="Medium"></img></a>
                    <a href="https://www.facebook.com/GRECASPlatform/" target='_blank' rel="noreferrer"><img src={facebook} alt="Facebook"></img></a>
                    <a href="https://www.reddit.com/user/GRECASToken" target='_blank' rel="noreferrer"><img src={reddit} alt="Reddit"></img></a>
                    <a href="https://www.instagram.com/grecastoken/" target='_blank' rel="noreferrer"><img src={instagram} alt="Instagram"></img></a>
                    <a href="https://discord.gg/YvWDtytMJd" target='_blank' rel="noreferrer"><img src={discord} alt="Discord"></img></a>
                    <a href="https://github.com/GCSToken" target='_blank' rel="noreferrer"><img src={github} alt="Github"></img></a>
                    <a href="https://youtube.com" target='_blank' rel="noreferrer"><img src={youtube} alt="Youtube"></img></a>
                </div>        
            </div>
        </footer>
    );
};

export default Footer;