import './widgets.scss';

import logo from './icons/logo.png';

import bnb from './icons/1839.png'
import wrapped from './icons/Logo Wrapped BSC GRECAS (WBGCS) 625x625.png';
import cop from './icons/logo-cop.png';
import pank from './icons/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png';
import trust from './icons/trust.png';

const Widgets = () => {
    return (
        <div className='widgets'>
            <div className='widget-embedded'>
                <div className="nomics-ticker-widget" data-name="Grecas" data-base="GCS3" data-quote="BNB"></div>
                <div className="widget-buttons">
                    <p>Add liquidity</p>
                    <a href="http://pancakeswap.finance/add/BNB/0xa529A7879685B151c0b0b91D51c16aaf1C9450ed" target="_blank" rel="noreferrer">
                        <img src={bnb} alt="button"></img>
                    </a>
                    <a href="http://pancakeswap.finance/add/0xa529A7879685B151c0b0b91D51c16aaf1C9450ed/0xe1a499B4d829e522Ef6e2bfCdc63c4E19ae992f0" target="_blank" rel="noreferrer">
                        <img src={wrapped} alt="button"></img>
                    </a>
                    <a href="http://pancakeswap.finance/add/0x0Eb3a705fc54725037CC9e008bDede697f62F335/0xa529A7879685B151c0b0b91D51c16aaf1C9450ed" target="_blank" rel="noreferrer">
                        <img src={cop} alt="button"></img>
                    </a>
                    <a href="http://pancakeswap.finance/farms" target="_blank" rel="noreferrer">
                        <img src={pank} alt="Button"></img>
                    </a>
                </div>
                <div className="widget-links">
                    <a href="http://pancakeswap.finance/swap?outputCurrency=0xa529A7879685B151c0b0b91D51c16aaf1C9450ed" target="_blank" rel="noreferrer">Buy via Pancakeswap</a>
                    <a href="http://gcs.air.grecas.club" target="_blank" rel="noreferrer">Airdrop</a>
                    <a href="http://gcs.privat.grecas.club" target="_blank" rel="noreferrer">Presale</a>
                </div>
            </div>
            <div className='widget-embedded'>
                <div className="nomics-ticker-widget" data-name="Wrapped BSC GRECAS" data-base="WBGCS" data-quote="BNB"></div>
                <div className="widget-buttons">
                    <p>Add liquidity</p>
                    
                    <a href="http://pancakeswap.finance/add/BNB/0xe1a499B4d829e522Ef6e2bfCdc63c4E19ae992f0" target="_blank" rel="noreferrer">
                        <img src={bnb} alt="button"></img>
                    </a>
                    <a href="http://pancakeswap.finance/add/0xe1a499B4d829e522Ef6e2bfCdc63c4E19ae992f0/0xa529A7879685B151c0b0b91D51c16aaf1C9450ed" target="_blank" rel="noreferrer">
                        <img style={{width: '48px', height: '48px', position: 'relative', top: '-3px'}} src={logo} alt="button"></img>
                    </a>
                    <a href="http://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xe1a499B4d829e522Ef6e2bfCdc63c4E19ae992f0" target="_blank" rel="noreferrer">
                        <img src={trust} alt="button"></img>
                    </a>
                    <a href="http://pancakeswap.finance/farms" target="_blank" rel="noreferrer">
                        <img src={pank} alt="Button"></img>
                    </a>
                </div>
                <div className="widget-links">
                    <a href="http://pancakeswap.finance/swap?outputCurrency=0xe1a499B4d829e522Ef6e2bfCdc63c4E19ae992f0" target="_blank" rel="noreferrer">Buy via Pancakeswap</a>
                    <a href="http://wbgcs.air.grecas.club" target="_blank" rel="noreferrer">Airdrop</a>
                    <a href="http://wbgcs.privat.grecas.club" target="_blank" rel="noreferrer">Presale</a>
                </div>
            </div>
        </div>
    )
};

export default Widgets;