import './App.scss';

import Footer from './components/Footer'; 
import Widgets from './components/Widgets'

import block from './components/icons/block.svg';
import icc from './components/icons/xlm-crypto-cryptocurrency-cryptocurrencies-cash-money-bank-payment_95540.svg';
import logo from './components/icons/logo.svg';
import card from './components/icons/security_code_payment_method_card_icon_142732.svg';

import psxe from './components/icons/epsxe_games_512.png';

function App() {
  return (
    <div className="App">

      <div className='container'>
        <div className='main-container'>

          <Widgets />

          <div className="title-block">
            <div className="title-logo animate__animated animate__fadeInRight animate__delay-2s	">
              <img src="logo.png" alt="Logo"></img>
              <h1><span>GR</span>ECA<span className='green'>S</span></h1>
            </div>
            
            <div className="title-list">
            <a className="title-block-img animate__animated animate__fadeInDown animate__delay-4s" href="http://gov.grecas.club" target="_blank" rel="noreferrer"><img src={block} alt="Блок"></img></a>
            <a className="title-list-img animate__animated animate__fadeInDown animate__delay-3s" href="http://game.grecas.club" target="_blank" rel="noreferrer"><img src={psxe} alt="Блок"></img>Games   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg></a>
            <a className="title-list-img animate__animated animate__fadeInDown animate__delay-3s" href="http://boost.marsiswap.club" target="_blank" rel="noreferrer"><img src={icc} alt="Блок"></img>Add boost   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg></a>
            <a className="title-list-img animate__animated animate__fadeInDown animate__delay-3s" href="http://www.marsiswap.club" target="_blank" rel="noreferrer"><img src={logo} alt="Блок"></img>Explore marsi   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg></a>
            <a className="title-list-img animate__animated animate__fadeInDown animate__delay-3s" href="http://pay.grecas.club" target="_blank" rel="noreferrer"><img src={card} alt="Блок"></img>Payment by cart   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg></a>
            </div>
          </div>

        </div>
      </div>

      <Footer />      
    </div>
  );
}

export default App;
