import React from 'react';
import ReactDOM from 'react-dom';
import {Helmet} from "react-helmet";
import 'normalize-scss/sass/_normalize.scss';
import './index.scss';
import 'animate.css';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <script src="https://widget.nomics.com/embed.js"></script>
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

